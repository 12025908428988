<!--  -->
<template>
<div id="content">
    <!-- <div style="width: 90%;margin:0 auto;">
        <canvas id="report" width="1350px" height="150px"  style="width: 98%;">您的浏览器不支持canvas</canvas><br>
    </div> -->
    <div >
        <canvas id="report" width="1200px" height="150px" style="margin:0 auto; display:block" >您的浏览器不支持canvas</canvas><br>
    </div>
    <div class="rowclearfix" >
        <div>
            <table cellpadding="5" cellspacing="0" class="tablecss" width="600px" rules="rows">
                <thead align="center">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="4" >1.心电检测结果（*getDate()）</th>
                </tr>
                <tr>
                    <th width="70">编号</th>
                    <th width="220">检测项目</th>
                    <th width="220">疑似度(%)</th>
                    <th width="100">结论</th>
                </tr>
                </thead>

                <tbody align="center">
                <tr id="tr_info_xdgs">
                    <td>1</td>
                    <td>心动过速</td>
                    <td>分值</td>
                    <td>正常与否</td>
                </tr>
                <tr id="tr_info_sxxdgs">
                    <td>2</td>
                    <td>室性心动过速</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_xdgh">
                    <td>3</td>
                    <td>心动过缓</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_fxyb">
                    <td>4</td>
                    <td>交界性逸搏</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_sxyb">
                    <td>5</td>
                    <td>室性逸搏</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_xlbq">
                    <td>6</td>
                    <td>心律不齐</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_sxzb">
                    <td>7</td>
                    <td>室性早搏</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_fxzb">
                    <td>8</td>
                    <td>房性早搏</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_jjxzb">
                    <td>9</td>
                    <td>交界性早搏</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr id="tr_info_fc">
                    <td>10</td>
                    <td>房颤</td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <br>
            <table cellpadding="5" cellspacing="0" class="tablecss" width="600px" rules="rows">
                <thead align="center">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="6" class="text-center">2.血压检测结果（）</th>
                </tr>
                </thead>
                <tbody align="center">
                <tr>
                    <td width="70"><b>高压</b></td>
                    <td width="90"></td>
                    <td width="90"><b>参考范围</b></td>
                    <td width="170">高压：90-140</td>
                    <td width="70"><b>心率</b></td>
                    <td wdith="90"></td>
                </tr>
                <tr>
                    <td class="text-center"><b>低压</b></td>
                    <td class="text-center"></td>
                    <td class="text-center"><b>参考范围</b></td>
                    <td class="text-center">低压：60-90</td>
                    <td class="text-center"><b>结论</b></td>
                    <td class="text-center"></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div >
            <table cellpadding="5" cellspacing="0" class="tablecss" width="600px" rules="rows">
                <thead align="center">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="4" class="text-center">3.尿检检测结果（）</th>
                </tr>
                <tr>
                    <th class="text-center">编号</th>
                    <th class="text-center">检测项目</th>
                    <th class="text-center">检验结果</th>
                    <th class="text-center">参考值</th>
                </tr>
                </thead>
                <tbody align="center">
                <tr id="tr_urine_leu">
                    <td>1</td>
                    <td>白细胞(LEU)</td>
                    <td id="leu"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_nit">
                    <td>2</td>
                    <td>亚硝酸盐(NIT)</td>
                    <td id="nit"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_ubg">
                    <td>3</td>
                    <td>尿胆原(UBG)</td>
                    <td id="ubg"></td>
                    <td>阴性/弱阳性</td>
                </tr>
                <tr id="tr_urine_pro">
                    <td>4</td>
                    <td>蛋白质(PRO)</td>
                    <td id="pro"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_ph">
                    <td>5</td>
                    <td>酸碱度(pH)</td>
                    <td id="ph"></td>
                    <td>4.5-8.0</td>
                </tr>
                <tr id="tr_urine_sg">
                    <td>6</td>
                    <td>尿比重(SG)</td>
                    <td id="sg"></td>
                    <td>1.005-1.035</td>
                </tr>
                <tr id="tr_urine_ca">
                    <td>7</td>
                    <td>钙离子(Ca)</td>
                    <td id="ca"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_bld">
                    <td>8</td>
                    <td>潜血(BLD)</td>
                    <td id="bld"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_ket">
                    <td>9</td>
                    <td>酮体(KET)</td>
                    <td id="ket"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_bil">
                    <td>10</td>
                    <td>胆红素(BIL)</td>
                    <td id="bil"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_glu">
                    <td>11</td>
                    <td>葡萄糖(GLU)</td>
                    <td id="glu"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_vc">
                    <td>12</td>
                    <td>维生素C(VC)</td>
                    <td id="vc"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_ma">
                    <td>13</td>
                    <td>微量白蛋白(MA)</td>
                    <td id="ma"></td>
                    <td>阴性</td>
                </tr>
                <tr id="tr_urine_cr">
                    <td>14</td>
                    <td>肌酐(Cr)</td>
                    <td id="cr"></td>
                    <td>阴性</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
<br>
    <div class="rowclearfix" style="width: 100%;margin:0 auto;">
        <div class="col-md-4 column">
            <table cellpadding="5" cellspacing="0" class="tablecss" width="400" rules="rows">
                <tbody align="center">
                 <tr>
                    <th bgcolor="#D3D3D3" colspan="2" class="text-center">4.血氧检测结果（）</th>
                </tr>
                <tr>
                    <td width="200"><b>血氧值(%)</b></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <td class="text-center"><b>参考范围</b></td>
                    <td class="text-center">>=94%为正常值</td>
                </tr>
                <tr>
                    <td class="text-center"><b>结论</b></td>
                    <td class="text-center"></td>
                </tr>
                </tbody>
               
            </table>
        </div>
        <div class="col-md-8 column">
            <table border="1" cellpadding="5" cellspacing="0" class="tablecss" width="800" rules="rows">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="5" class="text-center">5.血脂检测结果（）</th>
                </tr>
                <tbody align="center">
                <tr>
                    <th class="text-center">总胆固醇(mmol/L)</th>
                    <th class="text-center">高密度脂蛋白胆固醇(mmol/L)</th>
                    <th class="text-center">低密度脂蛋白胆固醇(mmol/L)</th>
                    <th class="text-center">甘油三酯(mmol/L)</th>
                    <th class="text-center">结论</th>
                </tr>
                <tr>
                    <td class="text-center">值</td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td rowspan="2" class="text-center" style="vertical-align: middle"></td>
                </tr>
                <tr>
                    <td class="text-center">&lt;5.17为正常值</td>
                    <td class="text-center">&lt;1.04为正常值</td>
                    <td class="text-center">&lt;3.4为正常值</td>
                    <td class="text-center">&lt;1.7为正常值</td>
                </tr>
                </tbody>

            </table>
        </div>
    </div>
<br>
    <div class="rowclearfix" style="width: 100%;margin:0 auto;">
        <div class="col-md-4 column">
            <table cellpadding="5" cellspacing="0" class="tablecss" width="400" rules="rows">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="2" class="text-center">6.血糖检测结果（）</th>
                </tr>
                <tbody align="center">
                <tr>
                    <td width="200"><b>血糖值(mmol/L)</b></td>
                    <td ></td>
                </tr>
                <tr>
                    <td class="text-center"><b>测量状态</b></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <td class="text-center"><b>参考范围</b></td>
                    <td class="text-center">空腹血糖：3.9-6.1<br/>餐后两小时：&le;7.8</td>
                </tr>
                <tr>
                    <td class="text-center"><b>结论</b></td>
                    <td class="text-center"></td>
                </tr>
                </tbody>

            </table>
        </div>
        <div class="col-md-8 column">
            <table border="1" cellpadding="5" cellspacing="0" class="tablecss" width="800" rules="rows">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="4" class="text-center">7.三合一检测结果</th>
                </tr>
                <tbody align="center">
                <tr>
                    <th class="text-center">项目</th>
                    <th class="text-center">血糖(mmol/L)</th>
                    <th class="text-center">尿酸(umol/L)</th>
                    <th class="text-center">胆固醇(mmol/L)</th>
                </tr>
                <tr>
                    <th class="text-center">测量时间</th>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <th class="text-center">数值</th>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <th class="text-center">测量状态</th>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <th class="text-center">参考范围</th>
                    <td class="text-center">空腹血糖：3.9-6.1<br/>餐后两小时：&le;7.8</td>
                    <td class="text-center">男：200-420<br/>女：140-360</td>
                    <td class="text-center">&lt;5.17为正常值</td>
                </tr>
                <tr>
                    <th class="text-center">结论</th>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
<br>
    <div class="rowclearfix" style="width: 100%;margin:0 auto;">
        <div class="col-md-4 column">
            <table cellpadding="5" cellspacing="0" class="tablecss" width="400" rules="rows">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="2" class="text-center">8.体温检测结果（）</th>
                </tr>
                <tbody align="center">
                <tr>
                    <td width="200"><b>体温(℃)</b></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <td class="text-center"><b>耳温参考范围</b></td>
                    <td class="text-center"></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8 column">
            <table cellpadding="5" cellspacing="0" class="tablecss" width="800" rules="rows">
                <tr>
                    <th bgcolor="#D3D3D3" colspan="6" class="text-center">9.体脂检测结果（）</th>
                </tr>
                <tbody align="center">
                <tr>
                    <th class="text-center">项目</th>
                    <th class="text-center">BMI</th>
                    <th class="text-center">体重(kg)</th>
                    <th class="text-center">体脂率(%)</th>
                    <th class="text-center">体水分(%)</th>
                    <th class="text-center">基础代谢量(kcal)</th>
                </tr>
                <tr>
                    <th class="text-center">结果</th>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <th class="text-center">参考值</th>
                    <td class="text-center">18-25</td>
                    <td class="text-center"></td>
                    <td class="text-center">11-21</td>
                    <td class="text-center">55-65</td>
                    <td class="text-center">800-2000</td>
                </tr>
                <tr>
                    <th class="text-center">项目</th>
                    <th class="text-center">皮下脂肪率(%)</th>
                    <th class="text-center">内脏脂肪等级</th>
                    <th class="text-center">骨骼肌率(%)</th>
                    <th class="text-center">骨量(kg)</th>
                    <th class="text-center">蛋白质(%)</th>
                </tr>
                <tr>
                    <th class="text-center">结果</th>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                </tr>
                <tr>
                    <th class="text-center">参考值</th>
                    <td class="text-center">5-25</td>
                    <td class="text-center">0-9</td>
                    <td class="text-center">49-59</td>
                    <td class="text-center">2.3-2.7</td>
                    <td class="text-center">16-20</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
<br>
    <div style="width: 100%;margin:0 auto;">
        <table  cellpadding="5" cellspacing="0" class="tablecss" width=1210 align="center" rules="rows">
            <thead>
            <tr>
                <th bgcolor="#D3D3D3" colspan="6" class="text-center">10.生化分析仪检测结果（）</th>
            </tr>
            <tr>
                <th class="text-center">项目名称</th>
                <th class="text-center">中文名称</th>
                <th class="text-center">检验结果</th>
                <th class="text-center">结果提示</th>
                <th class="text-center">参考范围</th>
                <th class="text-center">参考单位</th>
            </tr>
            </thead>
            <tbody align="center">
            <tr id="tr_bio_TP">
                <td>TP</td>
                <td>总蛋白</td>
                <td></td>
                <td><span id='TP_conclusion'></span></td>
                <td>65-85</td>
                <td>g/L</td>
            </tr>
            <tr id="tr_bio_ALB">
                <td>ALB</td>
                <td>白蛋白</td>
                <td></td>
                <td><span id='ALB_conclusion'></span></td>
                <td>40-55</td>
                <td>g/L</td>
            </tr>
            <tr id="tr_bio_GLO">
                <td>GLO</td>
                <td>球蛋白</td>
                <td></td>
                <td><span id='GLO_conclusion'></span></td>
                <td>20-40</td>
                <td>g/L</td>
            </tr>
            <tr id="tr_bio_A_G">
                <td>A/G</td>
                <td>白球比</td>
                <td></td>
                <td><span id='A_G_conclusion'></span></td>
                <td>1.2-2.4</td>
                <td> </td>
            </tr>
            <tr id="tr_bio_TBIL">
                <td>TBIL</td>
                <td>总胆红素</td>
                <td></td>
                <td><span id='TBIL_conclusion'></span></td>
                <td>3.4-20</td>
                <td>umol/L</td>
            </tr>
            <tr id="tr_bio_ALT">
                <td>ALT</td>
                <td>谷丙转氨酶</td>
                <td></td>
                <td><span id='ALT_conclusion'></span></td>
                <td>9-50</td>
                <td>U/L</td>
            </tr>
            <tr id="tr_bio_AST">
                <td>AST</td>
                <td>谷草转氨酶</td>
                <td></td>
                <td><span id='AST_conclusion'></span></td>
                <td>15-40</td>
                <td>U/L</td>
            </tr>
            <tr id="tr_bio_GGT">
                <td>GGT</td>
                <td>谷酰转肽酶</td>
                <td></td>
                <td><span id='GGT_conclusion'></span></td>
                <td>10-60</td>
                <td>U/L</td>
            </tr>
            <tr id="tr_bio_UREA">
                <td>UREA</td>
                <td>尿素</td>
                <td></td>
                <td><span id='UREA_conclusion'></span></td>
                <td>2.9-8.2</td>
                <td>mmol/L</td>
            </tr>
            <tr id="tr_bio_CRE">
                <td>CRE</td>
                <td>肌酐</td>
                <td></td>
                <td><span id='CRE_conclusion'></span></td>
                <td>54-109</td>
                <td>umol/L</td>
            </tr>
            <tr id="tr_bio_GLU">
                <td>GLU</td>
                <td>葡萄糖</td>
                <td></td>
                <td><span id='GLU_conclusion'></span></td>
                <td>3.9-6.1</td>
                <td>mmoml/L</td>
            </tr>
            </tbody>
        </table>
    </div>
    <br>
<div align="center">
    <el-button type="primary" plain id="export">下载检验报告</el-button>
    <el-button type="primary" plain id="suggestion">心狗建议</el-button>
</div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    initCanvas() {console.log("初始化canvas")
    let canvas = document.getElementById('report');      
    let can = canvas.getContext('2d');               
    can.font=" 40px 微软雅黑";
    can.textAlign="start";
    can.fillText("心狗健康体检报告单",420,40);
    can.moveTo(10,70);
    can.lineTo(1200,70);
    can.moveTo(10,80);
    can.lineTo(1200,80);
    can.stroke();
    can.beginPath(); 
    can.font="28px 仿宋";
    can.textAlign="start";
    can.fillText("姓名:   ",80,125);
    can.fillText("性别:   ",300,125);
    can.fillText("年龄:   ",520,125);
    can.fillText("身高:   ",720,125);
    can.fillText("体重:   ",940,125);
    // can.moveTo(0/scale,150/scale);
    // can.lineTo(1350/scale,150/scale);
    can.stroke();                
    }                       
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.initCanvas()
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}

</script>

<style>
.rowclearfix{
    display: flex;
    justify-content: center;
    gap:10px
}
.tablecss{
    border:4px solid gray
}
.canvas{
    display: block;
    margin: 0 auto;
}
</style>